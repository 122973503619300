import {mapMutations} from "vuex";

//sections
export default {
  name: "order-select",
  components: {},
  props: {
    position: {
      type: Number,
      default: '1'
    },
    title: {
      type: String,
      default: 'default'
    },
    passedStage: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {}
  },
  created() {
  },
  computed: {},
  mounted() {
  },
  watch: {},
  methods: {
    changeEvent() {
      this.$emit('change-event')
    },
    ...mapMutations({})
  }
}
