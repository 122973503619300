import {mapMutations, mapGetters, mapActions} from "vuex";
import {validation} from "@/mixins/validation.js";
//sections

import OrderProduct from "@/modules/ordering/components/order-product/index.vue";
import loginFirstStep from '@/modules/ordering/components/login-first-step/index.vue'
import loginSecondStep from '@/modules/ordering/components/login-second-step/index.vue'
import OrderSelect from '@/modules/ordering/components/order-select/index.vue'
import Delivery from "@/modules/ordering/components/delivery/index.vue";
import Payment from '@/modules/ordering/components/payment/index.vue'

export default {
    name: "ordering",
    components: {
        OrderProduct,
        loginFirstStep,
        loginSecondStep,
        OrderSelect,
        Delivery,
        Payment
    },

    data() {
        return {
            enabledButton: false,
            showNextStep: false,
            phoneError: false,
            phoneErrorAuth: false,
            emailError: false,
            townError: false,
            codeError: false,
            nameError: false,
            secondNameError: false,
            openContact: true,
            openDelivery: true,
            openPayment: false,
            passedStage: false,
            basketProducts: [],
            services: JSON.parse(localStorage.getItem('services')),
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: 'basket', title: this.$t('basket.basket'), slug: 'catalog'},
            ],
        }
    },
    mixins: ['validation'],
    computed: {
        ...mapGetters({
            smsLoginData: 'order/smsLogin',
            loginFirst: 'order/loginFirst',
            getRecipientData: 'order/otherRecipientData',
            globalRequestsFlag: 'system/globalRequestsFlag',
            productsFromBack: 'basket/secondBasket',
            isAuth: 'auth/isAuthenticated',
            deliveryDetails: 'order/selectedDelivery',
            paymentDetails: 'order/selectPayment',
            userDataFilled: 'order/userDataFilled',
            basket: 'basket/basketCount',
            orderLoading: 'order/orderLoading',
            orderData: 'order/orderData',
        }),

        isFinishedStep() {
            return this.isAuth && this.userDataFilled
        },
        isFinishedStepDelivery() {
            if (Object.values(this.deliveryDetails).length && this.openPayment) {
                return true
            }
            if (!Object.values(this.deliveryDetails).length || !this.openPayment) {
                return false
            }

        },

        getTotalSum() {
            let arr = []
            this.services = JSON.parse(localStorage.getItem('services'))
            if (this.services && this.services.length) {
                this.services.forEach(el => {
                    arr.push(el.price)
                })
            }
            if (this.basketProducts) {
                this.basketProducts.forEach(el => {
                    arr.push(el.quantity * el.select_type.price)
                })
            }
            return arr.reduce((partialSum, a) => partialSum + a, 0);
        }
    },

    created() {
        this.setRequestFlag(true);
        this.openContact = true
        this.openDelivery = false
        this.openPayment = false
        this.resetUserData(false)
        this.setDeliveries(false)


    },
    mounted() {
        document.title = `${this.$t('personalOffice.productsCatalog.processOrder')} | karbosnab`
        this.getBasket().then(() => {
            let products = this.basket.basket.products
            let gifts = this.basket.basket.selectedGiftProducts
            let arr = products.concat(gifts)
            this.basketProducts = arr
            for (let key in this.productsFromBack.giftProducts) {
                let arr = this.productsFromBack.giftProducts[key].products
                arr.forEach(el => el.parrentId = key)
                this.giftProducts = []
                this.giftProducts.push(arr)
            }

            // this.sendBasket(this.productsFromBack.basket).then(()=>{
            //   console.log(this.basket,'basket');
            // })
        })
    },
    watch: {
        'isFinishedStepDelivery': function () {

        },
        'deliveryDetails': function () {
            console.log(this.deliveryDetails, 'deliveryDetails');
            this.openPayment = true
            this.openDelivery = false

            this.deliveryDetails.checked && this.paymentDetails.checked ? this.enabledButton = true : this.enabledButton = false
        },
        'paymentDetails': function () {
            console.log(this.deliveryDetails, 'payment');
            this.deliveryDetails.checked && this.paymentDetails.checked ? this.enabledButton = true : this.enabledButton = false
        },

        'userDataFilled': function () {
            if (this.userDataFilled) {
                this.openDelivery = true
                this.openContact = false
            }

        },
        showNextStep() {
            this.showNextStep = true
        }
    },

    methods: {
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
            closeSuccessPopup: 'order/SHOW_SUCCESS_POPUP',
            resetBasket: 'basket/RESET_BASKET',
            resetUserData: 'order/USER_DATA_FILLED',
            setDeliveries: 'order/SELECT_DELIVERY',
            clearUserData: 'order/RESET_PAYMENT_DATA'
        }),
        ...mapActions({
            getBasket: 'basket/GET_BASKET',
            fetUserData: 'auth/FETCH_USER_DATA',
            fetchOrder: 'order/SEND_ORDER',
            sendBasket: 'basket/UPDATE_BASKET',
        }),
        getUserData: function () {
            this.$root.$emit('sendData')
        },
        getDeliveryData: function () {
            this.$root.$emit('sendDeliveryData')
        },
        getPaymentData: function () {
            this.$root.$emit('sendPaymentData')
        },
        async collectData() {
            try {
                this.getPaymentData()

                let obj = this.basket.basket
                // obj.products = this.basket.basket.products
                obj.order_id = this.basket.basket.basketId
                obj.user_info = this.loginFirst
                // obj.payment_details = this.paymentDetails
                obj.delivery_details = this.deliveryDetails

                obj.payment_details = {
                    payment_id: this.paymentDetails.data.payment_id,
                    ownership_type_id: this.paymentDetails.data.ownership_type_id,
                    payment_info: {}
                }

                if (this.paymentDetails.data.payment_info.document_nds_status) {
                    obj.payment_details.payment_info.document_nds_status = this.paymentDetails.data.payment_info.document_nds_status
                }
                if (this.paymentDetails.data.payment_info.edrpo) {
                    obj.payment_details.payment_info.edrpo = this.paymentDetails.data.payment_info.edrpo
                }
                if (this.paymentDetails.data.payment_info.filial_code) {
                    obj.payment_details.payment_info.filial_code = this.paymentDetails.data.payment_info.filial_code
                }
                if (this.paymentDetails.data.payment_info.filial_name) {
                    obj.payment_details.payment_info.filial_name = this.paymentDetails.data.payment_info.filial_name
                }
                if (this.paymentDetails.data.payment_info.inn) {
                    obj.payment_details.payment_info.inn = this.paymentDetails.data.payment_info.inn
                }
                if (this.paymentDetails.data.payment_info.organisation_name) {
                    obj.payment_details.payment_info.organisation_name = this.paymentDetails.data.payment_info.organisation_name
                }
                if (this.paymentDetails.data.payment_info.pdv_status) {
                    obj.payment_details.payment_info.pdv_status = this.paymentDetails.data.payment_info.pdv_status
                }

                await this.fetchOrder(obj)
                if (this.paymentDetails.data.payment_id === 1 || this.paymentDetails.data.payment_id === 5) {
                    this.resetBasket()
                    this.clearUserData()
                    this.$router.push({name: 'home'}).then(() => {
                        setTimeout(() => {
                            this.closeSuccessPopup(true)
                        }, 1500)
                    }).catch(() => {
                        setTimeout(() => {
                            this.closeSuccessPopup(true)
                        }, 1500)
                    })

                } else {
                    console.log(this.orderData, 'order-data');
                    setTimeout(() => {
                        this.resetBasket()
                        this.clearUserData()
                        document.getElementById('liqpay-form').submit()

                    }, 1500)
                    this.resetBasket()
                }

            } catch (e) {
                console.log(e);
                this.$toasted.error(e.message);
                this.getBasket()
            }
        },
        confirmOrder() {
            this.getUserData()
            this.getPaymentData()
            this.getDeliveryData()

            if (this.loginFirst && this.paymentDetails && this.deliveryDetails) {
                this.collectData()
            }

        },

        openContactSelect() {
            this.openContact = !this.openContact
        },
        openDeliverySelect() {
            this.openDelivery = !this.openDelivery
        },
        openPaymentSelect() {
            this.openPayment = !this.openPayment
        },
    }
}
