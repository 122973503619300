import {mapMutations, mapActions, mapGetters} from "vuex";
import {validation} from "@/mixins/validation.js";
//sections
import SmallLoader from "@/components/small-loader/index.vue";
import autocomplete from "@/components/autocomplete/index.vue";

export default {
    name: "login-first",
    components: {
        SmallLoader,
        autocomplete
    },
    props: {
        ...validation.props,
        errorName: {
            type: Boolean,
            default: false
        },
        errorSurname: {
            type: Boolean,
            default: false
        },
        errorPhone: {
            type: Boolean,
            default: false
        },
        errorEmail: {
            type: Boolean,
            default: false
        },
        errorTown: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            selectedCityRef: '',
            name: '',
            secondName: '',
            phone: '',
            email: '',
            town: '',
            validTown: false,
            nameError: false,
            secondNameError: false,
            loginFirstPhoneError: false,
            finishFirstStep: false,
            emailError: false,
            townError: false,
            openLogin: true
        }
    },
    mixins: ['validation'],
    created() {

    },
    mounted() {
        this.$root.$on('sendData', () => {
            this.setUserData({
                name: this.name,
                middle_name: this.secondName,
                phone: this.phone,
                email: this.email,
                city: this.town
            })
        })
    },

    watch: {
        name() {
            this.setUserData({
                name: this.name,
                middle_name: this.secondName,
                phone: this.phone,
                email: this.email,
                city: this.town
            })
            if (this.name.length > 0) {
                this.$refs.loginName.textContent = ''
                this.nameError = false
            }
            if (this.name.length >= 2) {

            }
        },
        secondName() {
            this.setUserData({
                name: this.name,
                middle_name: this.secondName,
                phone: this.phone,
                email: this.email,
                city: this.town
            })
            if (this.secondName.length > 0) {
                this.$refs.loginSecondName.textContent = ''
                this.secondNameError = false
            }
        },
        phone() {

            this.setUserData({
                name: this.name,
                middle_name: this.secondName,
                phone: this.phone.replace(/[^\d]/g, ''),
                email: this.email,
                city: this.town

            })

            if (this.phone.length > 0) {
                this.$refs.loginPhone.textContent = ''
                this.loginFirstPhoneError = false
            }


        },
        email() {
            this.setUserData({
                name: this.name,
                middle_name: this.secondName,
                phone: this.phone,
                email: this.email,
                city: this.town
            })
            if (this.email.length > 0) {
                this.$refs.loginRegEmail.textContent = ''
                this.emailError = false
            }

        },
        town() {
            this.setUserData({
                name: this.name,
                middle_name: this.secondName,
                phone: this.phone,
                email: this.email,
                city: this.town
            })
            this.$refs.loginTown.textContent = ''
            if (this.town.length > 0) {
                this.$refs.loginTown.textContent = ''
                this.townError = false
            }
        }

    },
    computed: {
        ...mapGetters({
            towns: 'order/townsList',
            loginFirst: 'order/loginFirst',
            citiesList: 'order/citiesList',
            npTownsLoading: 'order/npTownsLoading'
        }),
    },
    methods: {
        ...mapMutations({
            setUserData: 'order/SET_LOGIN_SMS_CODE',
            userDataFilled: 'order/USER_DATA_FILLED',
        }),
        ...mapActions({
            fetchTowns: 'order/NOVA_POSHTA_GET_TOWNS',
            sendBasket: 'basket/UPDATE_BASKET',
            getTownsList: 'order/GET_TOWNS_LIST',
        }),

        validationEmail() {
            let error = this.$refs.loginRegEmail
            let email = this.email
            let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
            if (error) {
                error.textContent = ''
            }
            switch (true) {
                case email.length === 0:
                    error.append(this.$t('form.required'));
                    this.emailError = true
                    break;
                case !regex.test(this.email):
                    error.append(this.$t('validation.email'));
                    this.emailError = true
                    break;
                default:
                    this.emailError = false
            }
        },
        validationTown() {
            let error = this.$refs.loginTown
            let town = this.town.length
            if (error) {
                error.textContent = ''
            }
            switch (true) {
                case town === 0:
                    error.append(this.$t('form.required'));
                    this.townError = true
                    break;
                case !this.selectedCityRef:
                    error.textContent = ''
                    error.append(this.$t('personalOffice.orders.cityError'));
                    this.townError = true
                    break;
                default:
                    this.townError = false
            }
        },
        requestTown(data, service) {
            this.selectedCityRef = ''
            if (data.length >= 3) {
                this.getTownsList({
                    'city_name': data,
                    'service': service
                })
            }
        },
        findRefCity() {
            setTimeout(() => {
                let city = this.citiesList.find(el => el.description === this.town)
                if (city) {
                    this.selectedCityRef = city.ref
                }

            }, 500)

        },
        openContactSelect() {
            this.openLogin = !this.openLogin
        },
        confirmOrder() {
            validation.methods.validation(this.$refs.loginName, this.name, 2)
            validation.methods.validation(this.$refs.loginSecondName, this.secondName, 2)
            validation.methods.validation(this.$refs.loginPhone, this.phone, 19)

            // !this.selectedCityRef ? this.$refs.loginTown.innerHTML = 'Введіть коректне місто' : this.$refs.loginTown.innerHTML = ''
            this.validationEmail()
            this.validationTown()
            if (this.phone.length < 19) {
                this.loginFirstPhoneError = true
            }
            if (this.secondName.length < 2) {
                this.secondNameError = true
            }
            if (this.name.length < 2) {
                this.nameError = true
            }

            if (!this.nameError && !this.secondNameError && !this.loginFirstPhoneError && !this.emailError && !this.townError && this.selectedCityRef) {
                this.userDataFilled(true)
            }
        },
    }
}
