import {mapMutations, mapGetters, mapActions} from "vuex";
import autocomplete from "@/components/autocomplete/index.vue";
import smallLoader from "@/components/small-loader/index.vue";
import {validation} from "@/mixins/validation";
//sections
export default {
    name: "payment",
    components: {
        autocomplete,
        smallLoader
    },
    props: {},
    data() {
        return {
            paymentType: '',
            errorPaymentType: false,
            selectedOrganizationType: '',
            ipn: '',
            edrpo: '',
            payment: '',
            filialEdrpo: '',
            filialName: ''
        }
    },
    mixins: ['validation'],
    watch: {
        payment() {
            this.$refs.errorWithOutVAT.innerHTML = ''
            this.$refs.errorWithVAT.innerHTML = ''
            if (this.ipnData.pdvStatus) {
                console.log(this.filialEdrpo,'a');
                if (this.ipn.length === 10 && this.payment && this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    console.log('a1');
                    this.selectPayment(
                        {
                            checked: true,
                            data: this.paymentsData
                        }
                    )
                }
                if (this.edrpo.length === 8 && this.payment && this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    console.log('b');
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
                if (this.edrpo.length === 8 && this.payment && !this.filialEdrpo && !this.filialName) {
                    console.log('bb');
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }

            } else {
                if (this.ipn.length === 10 && this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    console.log('c');
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
                if (this.edrpo.length === 8 && this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    console.log('d');
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            }

        },
        selectedOrganizationType() {
            this.selectPayment({checked: false})
            this.resetData()
            this.ipn = ''
            this.edrpo = ''
            this.payment = ''
            this.filialEdrpo = ''
            this.filialName = ''
        },
        filialEdrpo() {
            if (this.ipnData.pdvStatus) {
                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1 && this.payment) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            } else {
                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            }

        },
        filialName() {
            if (this.ipnData.pdvStatus) {
                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1 && this.payment) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            } else {
                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            }
        },
        edrpo() {
            this.$refs.errorEdrpo.innerHTML = ''
            if (this.edrpo.length === 8) {
                this.sendIpn({code: this.edrpo}).then(() => {
                    this.$toasted.success(this.$t('personalOffice.organization.success'));
                    if (this.ipnData.pdvStatus) {
                        if (this.payment) {
                            if (this.selectedOrganizationType.id === 4) {
                                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                                    this.selectPayment({
                                        checked: true,
                                        data: this.paymentsData
                                    })
                                }
                            } else {
                                this.selectPayment({
                                    checked: true,
                                    data: this.paymentsData
                                })
                            }

                        }
                    } else {
                        this.selectPayment({
                            checked: true,
                            data: this.paymentsData
                        })
                    }

                }).catch(() => {
                    this.$toasted.error(this.$t('personalOffice.organization.error'));
                })
            }
        },
        ipn() {
            this.$refs.errorIpn.innerHTML = ''
            if (this.ipn.length === 10) {
                this.sendIpn({code: this.ipn}).then(() => {
                    this.$toasted.success(this.$t('personalOffice.ipn.success'));
                    if (this.ipnData.pdvStatus) {
                        if (this.payment) {
                            if (this.selectedOrganizationType.id === 4) {
                                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                                    this.selectPayment({
                                        checked: true,
                                        data: this.paymentsData
                                    })
                                }
                            } else {
                                this.selectPayment({
                                    checked: true,
                                    data: this.paymentsData
                                })
                            }
                        }
                    } else {
                        this.selectPayment({
                            checked: true,
                            data: this.paymentsData
                        })
                    }
                }).catch(() => {
                    this.$toasted.error(this.$t('personalOffice.ipn.error'));
                })
            }
        },
        paymentType() {
            if (this.paymentType.id !== 5) {
                this.selectPayment({
                    checked: true,
                    data: this.paymentsData
                })
            }
            if (this.paymentType.id === 5) {
                this.selectPayment({checked: false})
                if (this.filialEdrpo.length >= 8 && this.filialName.length >= 1) {
                    this.selectPayment({
                        checked: true,
                        data: this.paymentsData
                    })
                }
            }
            this.$refs.emptyPaymentMethod.textContent = ''
        },

    },
    created() {

        if (!this.paymentList.length) {
            this.getPaymentType()
        }
        if (!this.organizationTypeList.length) {
            this.getOrganizationType()
        }
    },
    mounted() {
        this.resetData()
        this.$root.$on('sendPaymentData', () => {
            this.sendData()
        })

    },

    computed: {
        ...mapGetters({
            paymentList: 'order/paymentsList',
            organizationTypeList: 'order/calculationTypeList',
            setIpnLoading: 'order/setIpnLoading',
            ipnData: 'order/setIpn'
        }),
        paymentsData() {
            let obj = {}
            obj.ownership_type_id = this.selectedOrganizationType.id
            obj.payment_id = this.paymentType.id
            obj.payment_info = {
                inn: this.ipn,
                edrpo: this.edrpo,
                organisation_name: this.ipnData.fullName ? this.ipnData.fullName : '',
                pdv_status: this.ipnData.pdvStatus ? this.ipnData.pdvStatus : false,
                document_nds_status: this.payment === 'WithVAT' ? true : false,
                filial_code: this.filialEdrpo,
                filial_name: this.filialName
            }
            return obj
        }
    },
    methods: {
        ...mapMutations({
            resetData: 'order/RESET_IPN',
            selectPayment: 'order/SELECT_PAYMENT'
        }),
        ...mapActions({
            getPaymentType: 'order/GET_PAYMENTS_TYPE',
            getOrganizationType: 'order/CALCULATION_TYPE',
            sendIpn: 'order/SEND_IPN'
        }),
        validatePayment() {
            validation.methods.validation(this.$refs.emptyPaymentMethod, this.paymentType)

            if (!this.selectedOrganizationType) {
                validation.methods.validation(this.$refs.errorOrganizationType, this.selectedOrganizationType)
            }
            if (this.paymentType.key === 'cashless_payment') {
                if (this.selectedOrganizationType.edrpo) {
                    validation.methods.validation(this.$refs.errorEdrpo, this.edrpo)
                }
                if (!this.selectedOrganizationType.edrpo) {
                    validation.methods.validation(this.$refs.errorIpn, this.ipn)
                }
                if (!this.payment) {
                    validation.methods.validation(this.$refs.errorWithVAT, this.payment)
                    validation.methods.validation(this.$refs.errorWithOutVAT, this.payment)
                }
            }
        },
        sendData() {

            this.validatePayment()
            if (this.selectedOrganizationType && this.payment && this.paymentType.key === 'cashless_payment') {
                this.selectPayment({
                    checked: true,
                    data: this.paymentsData
                })
            }
            if (this.paymentType.key === 'in_cash') {
                this.selectPayment({
                    checked: true,
                    data: this.paymentsData
                })
            }
            if (this.paymentType.key === 'card_on_site') {
                this.selectPayment({
                    checked: true,
                    data: this.paymentsData
                })
            }
            // if (this.paymentType.key === 'privatbank') {
            //     let obj = {}
            //     obj.payment_id = this.paymentType.id
            //     obj.payment_info = {
            //         name: 'privatbank',
            //     }
            //     this.selectPayment(obj)
            // }
            // if (this.paymentType.key === 'monobank') {
            //     let obj = {}
            //     obj.payment_id = this.paymentType.id
            //     obj.payment_info = {
            //         name: 'monobank',
            //     }
            //     this.selectPayment(obj)
            // }
        },
        reset() {
            this.resetData()
            // this.ipn = ''
            // this.edrpo = ''
        }
    }
}
